import React, {Fragment, useLayoutEffect} from 'react';
import {graphql, navigate} from 'gatsby';
import useOrderSummary from '../../hooks/useOrderSummary';
import Spin from '../../components/Spin';
import cart from '../../utils/cart';
import SEO from "../../components/SEO";
import "../../styles/_all.scss";

export const query = graphql`
    query orderCompletePageQuery {
        settings: sanitySiteSettings {
            title
            id
            facebook
            instagram
            twitter
            _rawOpenGraph(resolveReferences: { maxDepth: 10 })
            _rawLogo(resolveReferences: { maxDepth: 10 })
        }
    }
`;

export default ({ data }) => {
  const productId = cart.get('product_id');

  useLayoutEffect(() => {
    if (!productId) {
     navigate('/404');
    }
  }, [])

  const {
    loading,
    email,
    plan,
    immediate,
    immediateTotal,
    yearly,
    yearlyTotal,
    monthly,
    monthlyTotal
  } = useOrderSummary(productId);

  if (loading) return <Spin spinning />;

  return (
    <Fragment>
      <SEO
        title="Order Complete"
        siteName={data.settings.title}
        description={data.settings.description}
      />
      <div className="checkout-layout">
        <div className="sidebar">
          <div className="sidebar-content">
            <div className="logo">
              <img src="/assets/svg/logo.svg" />
            </div>
            <div className="review">
              <div className="quote-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="32"
                  viewBox="0 0 42 32"
                >
                  <path
                    fill="#FFF"
                    fillRule="evenodd"
                    d="M18.2 23.1c0 2.4-.78 4.32-2.35 5.76-1.57 1.45-3.55 2.17-5.95 2.17-2.9 0-5.27-.95-7.13-2.85C.9 26.28-.03 23.55-.03 20c0-3.8 1.1-7.55 3.3-11.23C5.44 5.1 8.52 2.17 12.5.03l2.85 4.34C13.2 5.87 11.5 7.5 10.2 9.33c-1.27 1.82-2.12 3.93-2.53 6.33.74-.33 1.6-.5 2.6-.5 2.3 0 4.22.74 5.7 2.23 1.5 1.46 2.24 3.36 2.24 5.7zm22.82 0c0 2.4-.78 4.32-2.35 5.76-1.57 1.45-3.56 2.17-5.96 2.17-2.86 0-5.24-.95-7.1-2.85-1.86-1.9-2.8-4.63-2.8-8.18 0-3.8 1.1-7.55 3.3-11.23 2.2-3.67 5.27-6.6 9.24-8.74l2.85 4.34c-2.16 1.5-3.87 3.14-5.16 4.96-1.28 1.82-2.12 3.93-2.54 6.33.74-.33 1.6-.5 2.6-.5 2.3 0 4.2.74 5.7 2.23 1.5 1.46 2.23 3.36 2.23 5.7z"
                    opacity=".28"
                  />
                </svg>
              </div>
              <blockquote cite="http://facebook">
                <p>
                  One family said they had never felt so cared for by a church.
                </p>
                <footer>
                  <div className="review-avatar">
                    <img
                      src="/assets/images/people/client-review-david.jpg"
                      alt=""
                    />
                  </div>
                  <div className="review-author">
                    <div className="review-name">David Grieve</div>
                    <div className="review-rating">
                      <span className="review-badge">
                        5{' '}
                        <span className="star">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M4.5 0l1.4 2.82 3.1.45-2.25 2.2.53 3.1L4.5 7.1 1.72 8.56l.53-3.1L0 3.26l3.1-.44"
                            />
                          </svg>
                        </span>
                      </span>{' '}
                      from facebook
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
        <div className="checkout">
          <div className="checkout-main">
            <div className="checkout-container">
              <h1 className="checkout-heading" style={{ marginBottom: 0 }}>
                Congrats - Order Complete!
              </h1>
              <p>
                We're creating your account now. You will receive an email after your account has been created by our team.
              </p>
              <div style={{ marginBottom: 30 }}>
                <div className="embed-container">
                  <iframe
                    src="https://player.vimeo.com/video/235747118?autoplay=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;loop=1"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen="true"
                  />
                </div>
              </div>
              <p className="well" style={{ textAlign: 'center', fontSize: 18 }}>
                After account creation, your login information will be sent to <br />
                <b>
                  <a href="">{email}</a>
                </b>
              </p>
              <div className="checkout-cart">
                <div className="checkout-cart-row">
                  <strong>Your Subscription</strong>
                </div>
                <div className="checkout-cart-row">
                  <span>{plan.name}</span>
                  <span>${plan.price} per {plan.freq}</span>
                </div>
                { immediate.map(o => (
                  <div className="checkout-cart-row">
                    <span>{o.offer_statement_title}</span>{' '}
                    <span>${o.offer_cost} today</span>
                  </div>
                )) }
                { monthly.map(o => (
                  <div className="checkout-cart-row">
                    <span>{o.product_name}</span>{' '}
                    <span>${Number(o.product_amount)} per month</span>
                  </div>
                )) }
                { yearly.map(o => (
                  <div className="checkout-cart-row">
                    <span>{o.product_name}</span>{' '}
                    <span>${Number(o.product_amount)} per year</span>
                  </div>
                )) }
                <div className="checkout-cart-row-footer">
                  <span>
                    <strong>Total:</strong>
                  </span>{' '}
                  <span className="pull-right-md">
                  {immediateTotal ? `$${immediateTotal} today` : ''}
                    {immediateTotal && (monthlyTotal || yearlyTotal) ? ' and then ' : ''}
                    {monthlyTotal ? `$${monthlyTotal} per month ` : ''}
                    {yearlyTotal ? `$${yearlyTotal} per year ` : ''}
                    {plan.trialDuration ? `(after your ${plan.trialDuration} days trial)` : ''}
                </span>
                </div>
              </div>
              <hr />
              <h4>Text In Church FB Community</h4>
              <p>
                We've created a private Facebook Group to provide a safe place
                for the collaboration of ideas between pastors and their staff.
                Feel free to ask questions of other pastors, provide
                encouragement, and offer suggestions.
              </p>
              <p>
                <a href="https://www.facebook.com/groups/113295095669243/">
                  Join Us On Facebook
                </a>
              </p>
              <hr />
              <h4>Ready To Get Started?</h4>
              <p>
                We've created a resource library to help you get started with
                Text In Church.
              </p>
              <p>
                <a href="https://help.textinchurch.com/getting-started">
                  Watch These Videos
                </a>
              </p>
              <br />
            </div>
          </div>
          <div className="checkout-footer">
            <div className="checkout-container">
              <h4>Our Guarantee</h4>
              <p>
                We want to provide the best experience possible so we back our
                product with a Full 30 Day Money-Back Guarantee.
              </p>
              <h4>No Contracts</h4>
              <p>
                After your FREE {plan.trialDuration} day trial period, you'll automatically roll
                over to your membership. No Contracts.{' '}
                <strong>Cancel at any time.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

import apiClient from './apiClient';
import { toIdHash } from '../utils/base64';

export const createAccount = (payload) =>
  apiClient.post('API/1_0/account.php', payload);

export const loadPlans = () => apiClient.get('API/1_0/getPlanList.php', { params: { limit: 100,  product_brand: 'tic' } });

export const postUpsells = (accountId, items) =>
  apiClient.post('API/1_0/postUpsells.php', {
    account_id: toIdHash(accountId),
    offers: items
  });

export const updateContact = (id, data) =>
  apiClient.post('API/1_0/contact.php', { ...data, hash: id });

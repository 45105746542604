import { useState, useEffect } from 'react';
import { loadPlans } from '../services/account.service';
import { getItemDetails } from '../services/pages.svc';
import cart from '../utils/cart';

export default (planId) => {
  const [orderedPlan, setPlan] = useState();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const email = cart.get('email');
  const offers = cart.get('offers') || [];
  useEffect(() => {
    loadPlans().then((r) => {
      setPlans(r);
      setLoading(false);
    });
    getItemDetails(planId).then((r) => {
      setPlan(r);
    });
  }, []);

  if (!loading && orderedPlan) {
    const immediate = [];
    const yearly = [];
    const monthly = [];
    let yearlyTotal = 0;
    let monthlyTotal = 0;

    if (orderedPlan.freq === 'month') {
      monthlyTotal = orderedPlan.price;
    } else if (orderedPlan.freq === 'year') {
      yearlyTotal = orderedPlan.price;
    }

    offers.forEach((o) => {
      if (
        o.product_key.includes('yearly') ||
        o.product_key.includes('annual')
      ) {
        const planDetails = plans.find((p) => p.product_key === o.product_key);
        yearly.push(planDetails);
        yearlyTotal =
          yearlyTotal + (planDetails ? Number(planDetails.product_amount) : 0);
      } else if (
        o.product_key === '500_message_bundle' ||
        o.product_key.includes('monthly') ||
        o.product_key.includes('month')
      ) {
        const planDetails = plans.find((p) => p.product_key === o.product_key);
        monthly.push(planDetails);
        monthlyTotal =
          monthlyTotal + (planDetails ? Number(planDetails.product_amount) : 0);
      } else {
        immediate.push(o);
      }
    });

    const immediateTotal = immediate.reduce((memo, i) => {
      return memo + i.offer_cost;
    }, 0);

    return {
      plan: orderedPlan,
      email,
      offers,
      immediate,
      immediateTotal,
      yearly,
      yearlyTotal,
      monthly,
      monthlyTotal,
    };
  }

  return {
    loading: true,
  };
};
